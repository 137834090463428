import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "my-12" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "flex items-center justify-between border-b" }
const _hoisted_4 = { class: "flex justify-end mb-2 w-1/4 items-center" }
const _hoisted_5 = { class: "font-medium text-gray-900" }
const _hoisted_6 = { class: "text-sm font-medium text-gray-500" }
const _hoisted_7 = { class: "font-medium text-gray-900" }
const _hoisted_8 = { class: "uppercase" }
const _hoisted_9 = {
  key: 0,
  class: "pl-1"
}
const _hoisted_10 = { class: "text-sm font-medium text-gray-500" }
const _hoisted_11 = {
  key: 0,
  class: "text-sm font-medium capitalize text-gray-500"
}
const _hoisted_12 = {
  key: 1,
  class: "text-sm font-medium capitalize text-gray-500 flex items-center"
}
const _hoisted_13 = { class: "uppercase text-gray-600 ml-1" }
const _hoisted_14 = { class: "font-medium text-gray-900" }
const _hoisted_15 = { class: "text-sm font-medium capitalize text-gray-500" }
const _hoisted_16 = { class: "font-medium text-gray-900" }
const _hoisted_17 = {
  key: 0,
  class: "flex flex-row items-center space-x-2"
}
const _hoisted_18 = { key: 0 }
const _hoisted_19 = { key: 1 }
const _hoisted_20 = { class: "flex flex-row items-center justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ConsultStatusSelector = _resolveComponent("ConsultStatusSelector")!
  const _component_BaseTextInput = _resolveComponent("BaseTextInput")!
  const _component_FilterPopover = _resolveComponent("FilterPopover")!
  const _component_MkBadge = _resolveComponent("MkBadge")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_SpecialityButton = _resolveComponent("SpecialityButton")!
  const _component_PopoverLink = _resolveComponent("PopoverLink")!
  const _component_PopoverButton = _resolveComponent("PopoverButton")!
  const _component_BasePopover = _resolveComponent("BasePopover")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_BasePagination = _resolveComponent("BasePagination")!
  const _component_AssignConsultModal = _resolveComponent("AssignConsultModal")!
  const _component_ResolveReasonModal = _resolveComponent("ResolveReasonModal")!
  const _component_RejectReasonModal = _resolveComponent("RejectReasonModal")!
  const _component_LockConsultModal = _resolveComponent("LockConsultModal")!
  const _component_PatientLetter = _resolveComponent("PatientLetter")!
  const _component_BaseModal = _resolveComponent("BaseModal")!
  const _component_ReviewSummary = _resolveComponent("ReviewSummary")!
  const _component_DefaultLayout = _resolveComponent("DefaultLayout")!
  const _directive_allow = _resolveDirective("allow")!

  return (_openBlock(), _createBlock(_component_DefaultLayout, null, {
    default: _withCtx(() => [
      _createElementVNode("section", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_ConsultStatusSelector, {
              options: _ctx.status,
              "model-value": _ctx.selectedStatusIndex,
              "onUpdate:modelValue": _ctx.setSelectedStatusIndex
            }, null, 8, ["options", "model-value", "onUpdate:modelValue"]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_BaseTextInput, {
                modelValue: _ctx.search,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
                placeholder: _ctx.$t('platform.common.search'),
                icon: "search",
                "icon-position": "left",
                "icon-height": "16px",
                size: "small",
                disabled: _ctx.loading,
                onKeyup: _ctx.updateSearchDebounced
              }, null, 8, ["modelValue", "placeholder", "disabled", "onKeyup"]),
              _createVNode(_component_FilterPopover, {
                class: "flex ml-2",
                options: _ctx.filterOptions,
                position: "left",
                onConfirm: _cache[1] || (_cache[1] = ($event: any) => (_ctx.applyFilters($event)))
              }, null, 8, ["options"])
            ])
          ]),
          _createVNode(_component_DataTable, {
            loading: _ctx.loading,
            columns: _ctx.columns,
            rows: _ctx.status[_ctx.selectedStatusIndex].rows,
            sort: _ctx.sort,
            class: "w-full mt-6",
            onSort: _ctx.updateSort
          }, {
            "cell.date": _withCtx(({ row }) => [
              _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.formatDob(_ctx.displaySelectedStatusDate(row))), 1),
              _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.formatFromNow(_ctx.displaySelectedStatusDate(row))), 1)
            ]),
            "cell.last_name": _withCtx(({ row }) => [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("span", _hoisted_8, _toDisplayString(row.patient.last_name) + ",", 1),
                _createTextVNode(" " + _toDisplayString(row.patient.first_name) + " ", 1),
                (row.minor_patients && row.minor_patients.length > 0)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_9, [
                      _createVNode(_component_MkBadge, {
                        color: "primary",
                        size: "xsmall"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("custom.uhb.patient.major-indicator")), 1)
                        ]),
                        _: 1
                      })
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.getSexOrGender(row.patient)) + " ," + _toDisplayString(_ctx.$t("platform.patient.years", { age: row.patient.age })) + " (" + _toDisplayString(_ctx.formatDob(row.patient.date_of_birth)) + ") ", 1)
            ]),
            "cell.patient_ids": _withCtx(({ row }) => [
              (row.patient_mrn)
                ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(_ctx.$t("platform.patient.mrn-abbreviation")) + " : " + _toDisplayString(row.patient_mrn), 1))
                : _createCommentVNode("", true),
              (row.patient.nhs)
                ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                    _createVNode(_component_BaseIcon, { name: "nhs-logo" }),
                    _createElementVNode("div", _hoisted_13, " : " + _toDisplayString(row.patient.nhs), 1)
                  ]))
                : _createCommentVNode("", true)
            ]),
            "cell.clinic_name": _withCtx(({ row }) => [
              _createElementVNode("div", _hoisted_14, _toDisplayString(row.clinic.name), 1),
              _createElementVNode("div", _hoisted_15, _toDisplayString(row.clinic.provider ? row.clinic.provider.name : ""), 1)
            ]),
            "cell.location": _withCtx(({ row }) => [
              _createElementVNode("div", _hoisted_16, _toDisplayString(row.location ? row.location.name : ""), 1)
            ]),
            "cell.consult_type": _withCtx(({ row }) => [
              (row.patient.deceased_at)
                ? (_openBlock(), _createBlock(_component_MkBadge, {
                    key: 0,
                    class: "w-full",
                    color: "gray",
                    filled: "",
                    label: 
                _ctx.$t('custom.uhb.patient.deceased-at', {
                  deceasedDate: _ctx.formatDob(row.patient.deceased_at),
                })
              
                  }, null, 8, ["label"]))
                : (
                row.consult_type && row.consult_type.name === 'New Patient'
              )
                  ? (_openBlock(), _createBlock(_component_MkBadge, {
                      key: 1,
                      class: "w-full",
                      label: _ctx.$t('custom.uhb.worklist.new-patient'),
                      color: "primary",
                      stroked: ""
                    }, null, 8, ["label"]))
                  : (
                row.consult_type &&
                  row.consult_type.name === 'Follow-Up Patient'
              )
                    ? (_openBlock(), _createBlock(_component_MkBadge, {
                        key: 2,
                        class: "w-full",
                        label: _ctx.$t('custom.uhb.worklist.follow-up'),
                        color: "success",
                        stroked: ""
                      }, null, 8, ["label"]))
                    : _createCommentVNode("", true)
            ]),
            "cell.triage": _withCtx(({ row }) => [
              (row.triage_status)
                ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                    _createElementVNode("div", {
                      class: "rounded-full h-2 w-2",
                      style: _normalizeStyle('background-color: ' + row.triage_status.color)
                    }, null, 4),
                    _createElementVNode("div", null, _toDisplayString(row.triage_status.name), 1)
                  ]))
                : _createCommentVNode("", true)
            ]),
            "cell.reviewer": _withCtx(({ row }) => [
              (row.assigned_reviewer_user_name)
                ? (_openBlock(), _createElementBlock("div", _hoisted_18, _toDisplayString(row.assigned_reviewer_user_name), 1))
                : _createCommentVNode("", true)
            ]),
            "cell.status": _withCtx(({ value, row }) => [
              (row.patient.deceased_at)
                ? (_openBlock(), _createBlock(_component_MkBadge, {
                    key: 0,
                    class: "w-full",
                    color: "gray",
                    size: "small",
                    filled: "",
                    label: 
                _ctx.$t('custom.uhb.patient.deceased-at', {
                  deceasedDate: _ctx.formatDob(row.patient.deceased_at),
                })
              
                  }, null, 8, ["label"]))
                : (_openBlock(), _createElementBlock("span", _hoisted_19, _toDisplayString(value), 1))
            ]),
            "cell.rejected_reason_type": _withCtx(({ value }) => [
              _createTextVNode(_toDisplayString(_ctx.$t(`custom.uhb.consult.reject-reasons.${value}`)), 1)
            ]),
            "cell.actions": _withCtx(({ row }) => [
              _createElementVNode("div", _hoisted_20, [
                (row.status === 'Needs Scheduling')
                  ? _withDirectives((_openBlock(), _createBlock(_component_BaseButton, {
                      key: 0,
                      "right-icon": "chevron-right",
                      color: "ghost",
                      onClick: ($event: any) => (_ctx.showScheduleConsultModal(row))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("custom.uhb.worklist.schedule")), 1)
                      ]),
                      _: 2
                    }, 1032, ["onClick"])), [
                      [_directive_allow, 'consult:schedule']
                    ])
                  : (row.status === 'Awaiting Consult')
                    ? _withDirectives((_openBlock(), _createBlock(_component_BaseButton, {
                        key: 1,
                        "right-icon": "chevron-right",
                        color: "ghost",
                        onClick: ($event: any) => (_ctx.startConsult(row))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("custom.uhb.consult.start")), 1)
                        ]),
                        _: 2
                      }, 1032, ["onClick"])), [
                        [_directive_allow, 'consult:update']
                      ])
                    : (row.status === 'Consulting')
                      ? _withDirectives((_openBlock(), _createBlock(_component_BaseButton, {
                          key: 2,
                          "right-icon": "chevron-right",
                          color: "ghost",
                          onClick: ($event: any) => (_ctx.resumeConsult(row))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t("custom.uhb.consult.resume")), 1)
                          ]),
                          _: 2
                        }, 1032, ["onClick"])), [
                          [_directive_allow, 'consult:update']
                        ])
                      : _createCommentVNode("", true),
                (
                  row.status === 'Reviewing' &&
                    row.review &&
                    row.review.lockedBy &&
                    row.review.lockedBy.id !== _ctx.currentUserId
                )
                  ? (_openBlock(), _createBlock(_component_MkBadge, {
                      key: 3,
                      color: "gray",
                      class: "btn rounded-full flex-col justify-center"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_BaseIcon, {
                          name: "lock-2",
                          position: "left",
                          height: "16px"
                        }),
                        _createTextVNode(" " + _toDisplayString(_ctx.$t("custom.uhb.review.locked-by", {
                    user: row.review.locked_by_user_full_name,
                  })), 1)
                      ]),
                      _: 2
                    }, 1024))
                  : (row.status === 'Awaiting Review')
                    ? _withDirectives((_openBlock(), _createBlock(_component_BaseButton, {
                        key: 4,
                        "right-icon": "chevron-right",
                        color: "ghost",
                        "data-dusk": `consults-start-review-${row.id}`,
                        onClick: ($event: any) => (_ctx.startReview(row))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("custom.uhb.review.to-review")), 1)
                        ]),
                        _: 2
                      }, 1032, ["data-dusk", "onClick"])), [
                        [_directive_allow, 'review:update']
                      ])
                    : (
                  row.status === 'Reviewing' &&
                    (!row.review.locked ||
                      row.review.lockedBy.id === _ctx.currentUserId)
                )
                      ? _withDirectives((_openBlock(), _createBlock(_component_BaseButton, {
                          key: 5,
                          "right-icon": "chevron-right",
                          color: "ghost",
                          onClick: ($event: any) => (_ctx.continueConsult(row.patient.id, row.id, row.review.id))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t("platform.common.continue")), 1)
                          ]),
                          _: 2
                        }, 1032, ["onClick"])), [
                          [_directive_allow, 'review:update']
                        ])
                      : (row.status === 'Rejected')
                        ? _withDirectives((_openBlock(), _createBlock(_component_BaseButton, {
                            key: 6,
                            color: "ghost",
                            onClick: ($event: any) => (_ctx.showResolveModal(row))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t("custom.uhb.consult.rejected-resolve-button")), 1)
                            ]),
                            _: 2
                          }, 1032, ["onClick"])), [
                            [_directive_allow, 'consult:resolve']
                          ])
                        : _createCommentVNode("", true),
                _createVNode(_component_BasePopover, { position: "left" }, {
                  button: _withCtx(() => [
                    _createVNode(_component_SpecialityButton, {
                      class: "ml-3",
                      "data-dusk": "cl-menu-button"
                    })
                  ]),
                  menu: _withCtx(() => [
                    _createVNode(_component_PopoverLink, {
                      to: '/patients/' + row.patient.id
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("platform.patient.view")), 1)
                      ]),
                      _: 2
                    }, 1032, ["to"]),
                    (['Needs Scheduling'].includes(row.status))
                      ? _withDirectives((_openBlock(), _createBlock(_component_PopoverButton, {
                          key: 0,
                          onClick: ($event: any) => (_ctx.showScheduleConsultModal(row))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t("custom.uhb.worklist.schedule")), 1)
                          ]),
                          _: 2
                        }, 1032, ["onClick"])), [
                          [_directive_allow, 'consult:schedule']
                        ])
                      : _createCommentVNode("", true),
                    (['Awaiting Consult'].includes(row.status))
                      ? _withDirectives((_openBlock(), _createBlock(_component_PopoverButton, {
                          key: 1,
                          onClick: ($event: any) => (_ctx.startConsult(row))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t("custom.uhb.consult.start")), 1)
                          ]),
                          _: 2
                        }, 1032, ["onClick"])), [
                          [_directive_allow, 'consult:update']
                        ])
                      : _createCommentVNode("", true),
                    (['Awaiting Review'].includes(row.status))
                      ? _withDirectives((_openBlock(), _createBlock(_component_PopoverButton, {
                          key: 2,
                          onClick: ($event: any) => (_ctx.startReview(row))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t("custom.uhb.review.to-review")), 1)
                          ]),
                          _: 2
                        }, 1032, ["onClick"])), [
                          [_directive_allow, 'review:update']
                        ])
                      : _createCommentVNode("", true),
                    (
                      row.status === 'Reviewing' &&
                        (!row.review.locked ||
                          row.review.lockedBy.id === _ctx.currentUserId)
                    )
                      ? _withDirectives((_openBlock(), _createBlock(_component_PopoverButton, {
                          key: 3,
                          onClick: ($event: any) => (
                      _ctx.continueConsult(row.patient.id, row.id, row.review.id)
                    )
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t("platform.common.continue")), 1)
                          ]),
                          _: 2
                        }, 1032, ["onClick"])), [
                          [_directive_allow, 'review:update']
                        ])
                      : _createCommentVNode("", true),
                    (
                      [
                        'Needs Scheduling',
                        'Awaiting Consult',
                        'Consulting',
                      ].includes(row.status)
                    )
                      ? _withDirectives((_openBlock(), _createBlock(_component_PopoverButton, {
                          key: 4,
                          "data-dusk": "reject-for-distributed-clinic-button",
                          onClick: ($event: any) => (_ctx.openRejectReasonModal(row))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t("custom.uhb.consult.reject-for-distributed-clinic")), 1)
                          ]),
                          _: 2
                        }, 1032, ["onClick"])), [
                          [_directive_allow, 'consult:reject']
                        ])
                      : _createCommentVNode("", true),
                    (
                      row.review &&
                        !row.review.completed_at &&
                        row.review.locked &&
                        row.review.lockedBy.id !== _ctx.currentUserId
                    )
                      ? _withDirectives((_openBlock(), _createBlock(_component_PopoverButton, {
                          key: 5,
                          onClick: ($event: any) => (
                      _ctx.clickUnlockConsult(
                        row.patient,
                        row.review.id,
                        row.review.locked_by_user_full_name
                      )
                    )
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t("custom.uhb.review.unlock")), 1)
                          ]),
                          _: 2
                        }, 1032, ["onClick"])), [
                          [_directive_allow, 'review:update']
                        ])
                      : _createCommentVNode("", true),
                    (
                      row.status === 'Complete' ||
                        row.status === 'Sent to Emergency'
                    )
                      ? _withDirectives((_openBlock(), _createBlock(_component_PopoverButton, {
                          key: 6,
                          onClick: ($event: any) => (_ctx.openViewAndCopyReviewSummaryModal(row))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t("custom.uhb.review.summary.view")), 1)
                          ]),
                          _: 2
                        }, 1032, ["onClick"])), [
                          [_directive_allow, 'review_summary:read']
                        ])
                      : _createCommentVNode("", true),
                    (
                      row.status === 'Complete' ||
                        row.status === 'Sent to Emergency'
                    )
                      ? _withDirectives((_openBlock(), _createBlock(_component_PopoverButton, {
                          key: 7,
                          onClick: ($event: any) => (_ctx.viewPatientLetter(row))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t("custom.uhb.correspondence.view-patient-letter")), 1)
                          ]),
                          _: 2
                        }, 1032, ["onClick"])), [
                          [_directive_allow, 'patient_letter:read']
                        ])
                      : _createCommentVNode("", true),
                    (row.status === 'Complete')
                      ? _withDirectives((_openBlock(), _createBlock(_component_PopoverButton, {
                          key: 8,
                          onClick: ($event: any) => (_ctx.resendPatientLetter(row.patient.id, row.review.id))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t("custom.uhb.correspondence.resend-patient-letter")), 1)
                          ]),
                          _: 2
                        }, 1032, ["onClick"])), [
                          [_directive_allow, 'patient_letter:resend']
                        ])
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1024)
              ])
            ]),
            _: 1
          }, 8, ["loading", "columns", "rows", "sort", "onSort"]),
          (
            !_ctx.loading &&
              _ctx.status[_ctx.selectedStatusIndex].paginationTotal &&
              _ctx.status[_ctx.selectedStatusIndex].paginationTotal > _ctx.perPage
          )
            ? (_openBlock(), _createBlock(_component_BasePagination, {
                key: 0,
                "model-value": _ctx.page,
                "page-size": _ctx.perPage,
                length: _ctx.status[_ctx.selectedStatusIndex].paginationTotal,
                "sm-pages-limit": 5,
                "md-pages-limit": 8,
                "items-name": _ctx.$t('platform.navigation.patients').toLowerCase(),
                "onUpdate:modelValue": _ctx.changePage
              }, null, 8, ["model-value", "page-size", "length", "items-name", "onUpdate:modelValue"]))
            : _createCommentVNode("", true),
          (_ctx.scheduleConsultModalVisible)
            ? (_openBlock(), _createBlock(_component_AssignConsultModal, {
                key: 1,
                title: _ctx.$t('custom.uhb.consult.schedule-consult'),
                consult: _ctx.currentConsult,
                patient: _ctx.selectedPatient,
                "can-edit-patient-mrn": !_ctx.currentConsult.patient_mrn_at_clinic_provider,
                onClose: _ctx.closeScheduleConsultModal,
                onUpdatePatient: _ctx.updateAfterScheduled
              }, null, 8, ["title", "consult", "patient", "can-edit-patient-mrn", "onClose", "onUpdatePatient"]))
            : _createCommentVNode("", true),
          (_ctx.enableResolveReasonModal)
            ? (_openBlock(), _createBlock(_component_ResolveReasonModal, {
                key: 2,
                title: _ctx.$t('custom.uhb.consult.schedule-consult'),
                consult: _ctx.currentConsult,
                patient: _ctx.selectedPatient,
                error: _ctx.resolveReasonError,
                onUpdateErrorMessage: _ctx.updateResolveErrorMessage,
                onMarkAsResolved: _ctx.markAsResolved,
                onClose: _ctx.closeResolveReasonModal
              }, null, 8, ["title", "consult", "patient", "error", "onUpdateErrorMessage", "onMarkAsResolved", "onClose"]))
            : _createCommentVNode("", true),
          (_ctx.enableRejectReasonModal)
            ? (_openBlock(), _createBlock(_component_RejectReasonModal, {
                key: 3,
                title: _ctx.$t('custom.uhb.consult.schedule-consult'),
                consult: _ctx.currentConsult,
                patient: _ctx.selectedPatient,
                error: _ctx.rejectReasonError,
                onUpdateErrorMessage: _ctx.updateRejectErrorMessage,
                onMarkAsRejected: _ctx.markAsRejected,
                onClose: _ctx.closeRejectReasonModal
              }, null, 8, ["title", "consult", "patient", "error", "onUpdateErrorMessage", "onMarkAsRejected", "onClose"]))
            : _createCommentVNode("", true),
          (_ctx.enableLockConsultModal)
            ? (_openBlock(), _createBlock(_component_LockConsultModal, {
                key: 4,
                title: _ctx.$t('custom.uhb.review.unlock'),
                message: 
            _ctx.$t('custom.uhb.review.unlock-message', {
              user: _ctx.modalTargetLockedByUserFullName,
            })
          ,
                onClose: _ctx.closeLockConsultModal,
                onExit: _cache[2] || (_cache[2] = ($event: any) => (_ctx.unlockConsult(_ctx.selectedPatient.id, _ctx.modalTargetReviewId)))
              }, null, 8, ["title", "message", "onClose"]))
            : _createCommentVNode("", true),
          (_ctx.enablePatientLetterModal)
            ? (_openBlock(), _createBlock(_component_BaseModal, {
                key: 5,
                class: "inline-block bg-white",
                "vertical-align": "center",
                onClose: _ctx.closePatientLetter
              }, {
                default: _withCtx(() => [
                  (_ctx.modalTargetReviewId)
                    ? (_openBlock(), _createBlock(_component_PatientLetter, {
                        key: 0,
                        style: {"height":"calc(100vh - 48px)","width":"calc(100vh - 48px)"},
                        "patient-id": _ctx.selectedPatient.id,
                        "review-id": _ctx.modalTargetReviewId,
                        onClose: _ctx.closePatientLetter
                      }, null, 8, ["patient-id", "review-id", "onClose"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["onClose"]))
            : _createCommentVNode("", true),
          (_ctx.viewAndCopyReviewSummary)
            ? (_openBlock(), _createBlock(_component_BaseModal, {
                key: 6,
                class: "inline-block bg-white",
                "vertical-align": "center",
                onClose: _ctx.closeViewAndCopyReviewSummaryModal
              }, {
                default: _withCtx(() => [
                  (_ctx.modalTargetReviewId)
                    ? (_openBlock(), _createBlock(_component_ReviewSummary, {
                        key: 0,
                        patient: _ctx.selectedPatient,
                        "form-submission-id": _ctx.modalTargetFormSubmissionId,
                        clinician: _ctx.modalTargetFormSubmissionClinician,
                        "review-id": _ctx.modalTargetReviewId,
                        "clinic-name": _ctx.modalTargetClinic.name,
                        "clinic-address": _ctx.providerAddress,
                        "consult-completed-at": _ctx.modalTargetConsultCompletedAt,
                        onClose: _ctx.closeViewAndCopyReviewSummaryModal
                      }, null, 8, ["patient", "form-submission-id", "clinician", "review-id", "clinic-name", "clinic-address", "consult-completed-at", "onClose"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["onClose"]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }))
}