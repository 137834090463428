
import { Vue, Options } from 'vue-class-component';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import DefaultLayout from '@/lib/layouts/DefaultLayout.vue';
import {
  BPMLocation,
  Clinic,
  Consult,
  ConsultTabStatus,
  PaginatedResponse,
  Patient,
  RejectReasonValue,
  ResolveReasonValue
} from '@/models';
import {
  ConsultService,
  OrganisationLocationService,
  PatientFormSubmissionService,
  PatientReviewService
} from '@/services/api';
import {
  BaseButton,
  BaseIcon,
  BaseModal,
  BasePagination,
  BasePopover,
  BaseTextInput,
  DataTable,
  LockConsultModal,
  PopoverButton,
  PopoverLink,
  SpecialityButton,
  FilterPopover
} from '@/lib/components';
import ConsultStatusSelector from '@/lib/components/Select/ConsultStatusSelector.vue';
import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios';
import {
  getDobFromISOString,
  getNhsNumberFromPatient
} from '@/helpers/patient.helper';
import debounce from 'lodash-es/debounce';
import AssignConsultModal from '@/views/worklists/AssignConsultModal.vue';
import RejectedReasonModal from '@/lib/components/Modals/RejectedReasonModal.vue';
import RejectReasonModal from '@/lib/components/Modals/RejectReasonModal.vue';
import {
  ConsultStatusSelectorOption,
  IColumn,
  IErrors,
  IFilterOptions,
  IFilterOptionsSelected
} from '@/lib';
import PatientLetter from '@/views/image-review/PatientLetter.vue';
import get from 'lodash-es/get';
import ReviewSummary from '@/lib/components/PatientSummary/ReviewSummary.vue';
import { isFeatureFlagEnabled } from '@/helpers/feature-flag.helper';
import { FEATURES, sexOptions, genderOptions } from '@/constants';
import { TranslateResult } from 'vue-i18n/index';
import ResolveReasonModal from '@/lib/components/Modals/ResolveReasonModal.vue';
import { useProgressStore } from '@/stores/progress.store';
import { useSessionStore } from '@/stores/session.store';
import { useNotificationStore } from '@/stores/notification.store';
dayjs.extend(relativeTime);

@Options({
  components: {
    ResolveReasonModal,
    ReviewSummary,
    PatientLetter,
    RejectReasonModal,
    RejectedReasonModal,
    AssignConsultModal,
    ConsultStatusSelector,
    LockConsultModal,
    PopoverButton,
    BaseIcon,
    BaseButton,
    PopoverLink,
    SpecialityButton,
    BasePopover,
    DefaultLayout,
    BasePagination,
    BaseTextInput,
    DataTable,
    BaseModal,
    FilterPopover
  }
})
export default class ConsultListPage extends Vue {
  progressStore = useProgressStore();
  sessionStore = useSessionStore();
  notificationStore = useNotificationStore();
  allConsultsFirstLoadingIsDone = false;
  loading = true;

  showFilter = false;
  selectedStatusIndex = 0;

  columns: IColumn[] = [];
  rows: Consult[] = [];
  perPage = 0;
  search = '';

  consultService = new ConsultService();
  request: CancelTokenSource | null = null;
  rejectReasonError: string | null = null;
  resolveReasonError: string | null = null;

  // Consult action
  selectedConsultId = '';
  scheduleConsultModalVisible = false;
  enableRejectReasonModal = false;
  enableResolveReasonModal = false;
  enableLockConsultModal = false;
  enablePatientLetterModal = false;
  viewAndCopyReviewSummary = false;

  modalTargetLockedByUserFullName = '';
  modalTargetReviewId = '';
  modalTargetClinic: Clinic | null = null;
  modalTargetConsultCompletedAt = '';
  modalTargetFormSubmissionId = '';
  modalTargetFormSubmissionClinician = '';
  status: ConsultStatusSelectorOption[] = [];

  locations: BPMLocation[] = [];
  filteredLocations?: IFilterOptionsSelected['checkboxItems'];

  sexOptions = sexOptions();
  genderOptions = genderOptions();

  // Reject
  currentConsult: Consult | null = null;
  selectedPatient: Patient | null = null;

  get locationService(): OrganisationLocationService {
    return new OrganisationLocationService(this.organisationId);
  }

  get organisationId() {
    return this.sessionStore.currentOrganisationId;
  }

  get currentUserId() {
    return this.sessionStore.currentUser.id;
  }

  get providerAddress() {
    return get(
      this.modalTargetClinic,
      'provider.address.formatted_address',
      ''
    );
  }

  get page() {
    return Number(this.$route.query.page) || 1;
  }

  get sort() {
    return this.$route.query.sort || 'patients.last_name';
  }

  get selectedStatus() {
    return this.status[this.selectedStatusIndex].statuses;
  }

  get filterOptions(): IFilterOptions | null {
    const newItems =
      this.locations.length > 0
        ? this.locations.map((location) => ({
          label: location.name,
          value: location.id
        }))
        : null;

    return newItems
      ? {
        checkboxItems: [
          {
            label: this.$t(
              'custom.uhb.consult.filter.location.label'
            ) as string,
            options: newItems
          }
        ]
      }
      : null;
  }

  get updateSearchDebounced() {
    return debounce(() => this.updateSearch(), 500);
  }

  beforeCreate() {
    this.status = [
      {
        label: this.$t('custom.uhb.consult.tab-all-consults') as string,
        shorthand: this.$t(
          'custom.uhb.consult.tab-all-consults-shorthand'
        ) as string,
        statuses: [],
        columns: [
          // TODO: Might need to change this once design is ready
          { name: 'date', label: this.$t('custom.uhb.consult.created-date') },
          { name: 'last_name', label: this.$t('platform.patient.patient') },
          { name: 'patient_ids' },
          { name: 'clinic_name', label: this.$t('custom.uhb.consult.clinic') },
          { name: 'location', label: this.$t('custom.uhb.consult.site') },
          { name: 'status', label: this.$t('platform.common.status') },
          { name: 'actions', label: '', align: 'right' }
        ],
        rows: [],
        total: 0,
        paginationTotal: 0
      },
      {
        label: this.$t('custom.uhb.consult.tab-unscheduled') as string,
        statuses: ['Needs Scheduling'],
        columns: [
          { name: 'date', label: this.$t('custom.uhb.consult.created-date') },
          { name: 'last_name', label: this.$t('platform.patient.patient') },
          { name: 'patient_ids' },
          { name: 'clinic_name', label: this.$t('custom.uhb.consult.clinic') },
          { name: 'location', label: this.$t('custom.uhb.consult.site') },
          {
            name: 'consult_type',
            label: this.$t('custom.uhb.consult.consult-type')
          },
          { name: 'actions', label: '', align: 'right' }
        ],
        rows: [],
        total: 0,
        paginationTotal: 0
      },
      {
        label: this.$t('custom.uhb.consult.tab-scheduled') as string,
        statuses: ['Awaiting Consult'],
        columns: [
          { name: 'date', label: this.$t('custom.uhb.consult.scheduled-date') },
          { name: 'last_name', label: this.$t('platform.patient.patient') },
          { name: 'patient_ids' },
          { name: 'clinic_name', label: this.$t('custom.uhb.consult.clinic') },
          { name: 'location', label: this.$t('custom.uhb.consult.site') },
          {
            name: 'consult_type',
            label: this.$t('custom.uhb.consult.consult-type')
          },
          { name: 'actions', label: '', align: 'right' }
        ],
        rows: [],
        total: 0,
        paginationTotal: 0
      },
      {
        label: this.$t('custom.uhb.consult.tab-consulting') as string,
        statuses: ['Consulting'],
        columns: [
          { name: 'date', label: this.$t('custom.uhb.consult.scheduled-date') },
          { name: 'last_name', label: this.$t('platform.patient.patient') },
          { name: 'patient_ids' },
          { name: 'clinic_name', label: this.$t('custom.uhb.consult.clinic') },
          { name: 'location', label: this.$t('custom.uhb.consult.site') },
          { name: 'triage', label: 'Triage' },
          {
            name: 'consult_type',
            label: this.$t('custom.uhb.consult.consult-type')
          },
          { name: 'actions', label: '', align: 'right' }
        ],
        rows: [],
        total: 0,
        paginationTotal: 0
      },
      {
        label: this.$t('custom.uhb.consult.tab-reviewing') as string,
        statuses: ['Awaiting Review', 'Reviewing'],
        columns: [
          { name: 'date', label: this.$t('custom.uhb.consult.consult-date') },
          { name: 'last_name', label: this.$t('platform.patient.patient') },
          { name: 'patient_ids' },
          { name: 'clinic_name', label: this.$t('custom.uhb.consult.clinic') },
          { name: 'location', label: this.$t('custom.uhb.consult.site') },
          { name: 'triage', label: 'Triage' },
          { name: 'reviewer', label: 'Reviewer' },
          { name: 'actions', label: '', align: 'right' }
        ],
        rows: [],
        total: 0,
        paginationTotal: 0
      },
      {
        label: this.$t('custom.uhb.consult.tab-rejected') as string,
        statuses: ['Rejected'],
        columns: [
          { name: 'date', label: this.$t('custom.uhb.consult.rejected-date') },
          { name: 'last_name', label: this.$t('platform.patient.patient') },
          { name: 'patient_ids' },
          { name: 'clinic_name', label: this.$t('custom.uhb.consult.clinic') },
          { name: 'location', label: this.$t('custom.uhb.consult.site') },
          {
            name: 'rejected_reason_type',
            label: this.$t('custom.uhb.consult.reason')
          },
          { name: 'actions', label: '', align: 'right' }
        ],
        rows: [],
        total: 0,
        paginationTotal: 0
      }
    ];
  }

  async mounted() {
    this.search = String(this.$route.query['filter[search]'] || '');
    this.columns = this.status[this.selectedStatusIndex].columns;
    await this.fetchConsultData();
    if (isFeatureFlagEnabled(FEATURES.ORGANISATION_LOCATIONS)) {
      this.fetchLocations();
    }
    this.allConsultsFirstLoadingIsDone = true;

    const unWatchRoute = this.$watch('$route', async (to, from) => {
      if (from.path === to.path && from.query !== to.query) {
        await this.fetchConsults();
        window.scroll({
          top: 0,
          behavior: 'smooth'
        });
      } else {
        unWatchRoute();
      }
    });

    // Re-fetch consults and location when switching organisation
    this.$watch('organisationId', async () => {
      this.search = '';
      if (Object.keys(this.$route.query).length) {
        await this.$router.replace({ path: this.$route.path });
      }
      await this.fetchConsultData();

      if (isFeatureFlagEnabled(FEATURES.ORGANISATION_LOCATIONS)) {
        await this.fetchLocations();
      }
    });
  }

  unmounted() {
    if (this.request) {
      this.request.cancel();
    }
  }

  async fetchLocations() {
    try {
      this.locations = (await this.locationService.index()).data;
    } catch (e) {
      await this.notificationStore.addErrorNotification({
        title: this.$t('custom.uhb.consult.fetch-locations-error')
      });
    }
  }

  displaySelectedStatusDate(consult: Consult) {
    switch (this.selectedStatusIndex) {
    case ConsultTabStatus.SCHEDULED:
    case ConsultTabStatus.CONSULTING:
      return consult.scheduled_at;
    case ConsultTabStatus.REVIEWING:
      return consult.completed_at;
    case ConsultTabStatus.REJECTED:
      return consult.rejected_at;
    default:
      return consult.created_at;
    }
  }

  formatDob(isoDate: string) {
    return isoDate ? this.$d(getDobFromISOString(isoDate), 'shortMonth') : '';
  }

  formatFromNow(dob: string) {
    return dayjs(dob).fromNow();
  }

  getSexOrGender(patient: Patient): string | TranslateResult | undefined {
    if (this.sessionStore.currentOrganisation?.uses_gender && patient.gender) {
      const gender = this.genderOptions.find(
        (option) => option.value === patient.gender
      );
      return gender ? gender.label : patient.gender;
    }
    const sex = this.sexOptions.find((option) => option.value === patient.sex);
    return sex ? sex.label : patient.sex;
  }

  async setSelectedStatusIndex(index: number) {
    if (this.allConsultsFirstLoadingIsDone) {
      this.selectedStatusIndex = index;
      this.emptyDatatable();
      const queryParams = this.$route.query;
      this.columns = this.status[this.selectedStatusIndex].columns;
      // eslint-disable-next-line no-negated-condition
      if (Object.keys(queryParams).length === 0) {
        await this.fetchConsults();
        window.scroll({
          top: 0,
          behavior: 'smooth'
        });
      } else {
        this.search = '';
        await this.$router.replace({ path: this.$route.path });
      }
    }
  }

  emptyDatatable() {
    this.status.map((status) => (status.rows = []));
  }

  toggleFilter() {
    this.showFilter = !this.showFilter;
  }

  async applyFilters(selectedFilters: IFilterOptionsSelected) {
    this.filteredLocations = selectedFilters.checkboxItems;
    await this.fetchConsultData();
  }

  async fetchConsultData() {
    await this.fetchConsults();
    await this.fetchCounts();
  }

  async updateSort(sort: string) {
    // Keep the search if present, but always reset the page
    await this.$router.replace({
      path: this.$route.path,
      query: {
        ...(this.search ? { search: this.search } : {}),
        sort
      }
    });
  }

  async updateSearch() {
    // Maintain sort order and only add search param when non-empty
    await this.$router.replace({
      path: this.$route.path,
      query: {
        ...(this.$route.query.sort ? { sort: this.$route.query.sort } : {}),
        ...(this.search ? { 'filter[search]': this.search } : {})
      }
    });
  }

  async changePage(page: number) {
    // Keep all existing query parameters
    await this.$router.replace({
      path: this.$route.path,
      query: {
        ...this.$route.query,
        page: page.toString()
      }
    });
  }

  // Fetch/Update the total number of unselected consults
  async fetchCounts() {
    this.loading = true;
    const copyStatus = [...this.status];
    copyStatus.splice(this.selectedStatusIndex, 1);
    await Promise.all(
      copyStatus.map((s) => this.fetchConsultCount(this.status.indexOf(s)))
    );
    this.loading = false;
  }

  async fetchConsultCount(index: number) {
    try {
      const requestConfig: AxiosRequestConfig = {
        params: {
          include:
            'patient,clinic,clinicConfiguration,review,formSubmission,consultType,triageStatus',
          perPage: 1,
          ...{
            'filter[status]': this.status[index].statuses,
            'filter[ownerOrProvider]': this.organisationId,
            'filter[location]': this.filteredLocations?.join(',')
          }
        }
      };
      const response = (await this.consultService.index(
        requestConfig
      )) as PaginatedResponse<Consult[]>;
      this.status[index].total = response.meta.total;
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.progressStore.setError();
        await this.notificationStore.addErrorNotification({
          title: this.$t('custom.uhb.consult.fetch-error')
        });
      }
    } finally {
      this.progressStore.finishProgress();
    }
  }

  async fetchConsults() {
    const targetSelectedIndex = this.selectedStatusIndex;
    this.loading = true;

    const requestConfig: AxiosRequestConfig = {
      params: {
        include:
          'patient,clinic,clinicConfiguration,review,formSubmission,consultType,triageStatus,location',
        page: this.page,
        sort: this.sort,
        ...(this.search ? { 'filter[search]': this.search } : {}),
        ...{
          'filter[status]': this.selectedStatus,
          'filter[ownerOrProvider]': this.organisationId,
          'filter[location]': this.filteredLocations?.join(',')
        }
      }
    };

    try {
      const response = (await this.consultService.index(
        requestConfig
      )) as PaginatedResponse<Consult[]>;

      this.status[targetSelectedIndex].rows = response.data.map(
        (consult: Consult) => {
          if (consult.patient) {
            consult.patient.mrn = consult.patient_mrn || '';
            consult.patient.nhs = getNhsNumberFromPatient(consult.patient);
          }

          if (consult?.patient?.deceased_at) {
            return { ...consult, isMuted: true };
          }

          return consult;
        }
      );
      this.perPage = response.meta.per_page;
      if (!this.search) {
        this.status[targetSelectedIndex].total = response.meta.total;
      }
      this.status[targetSelectedIndex].paginationTotal = response.meta.total;
      this.progressStore.finishProgress();
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.progressStore.setError();
        await this.notificationStore.addErrorNotification({
          title: this.$t('custom.uhb.consult.fetch-error')
        });
      }
    } finally {
      if (targetSelectedIndex === this.selectedStatusIndex) {
        this.loading = false;
      }
    }
  }

  getStatusIndexByName(name: string) {
    return this.status.findIndex((item) => item.label === name);
  }

  // Schedule Consult
  showScheduleConsultModal(consult: Consult) {
    this.selectedConsultId = consult.id;
    this.currentConsult = consult;
    this.selectedPatient = consult.patient ? consult.patient : null;
    this.scheduleConsultModalVisible = true;
  }

  closeScheduleConsultModal() {
    this.selectedConsultId = '';
    this.scheduleConsultModalVisible = false;
  }

  async updateAfterScheduled() {
    await this.fetchConsults();
    await this.fetchConsultCount(this.getStatusIndexByName('Unscheduled'));
    await this.fetchConsultCount(this.getStatusIndexByName('Scheduled'));
  }

  // Start a consult
  async startConsult(consult: Consult) {
    if (
      consult.clinic_configuration &&
      consult.clinic_configuration.forms &&
      consult.patient
    ) {
      const patientId = consult.patient.id;
      const formSubmissionService = new PatientFormSubmissionService(patientId);
      const response = await formSubmissionService.create({
        form_id: consult.clinic_configuration.forms[0].id,
        organisational_unit_id: this.sessionStore.currentOrganisationId,
        consult_id: consult.id
      });
      await this.$router.push(
        `/patients/${patientId}/forms/${response.data.id}`
      );
    }
  }

  // Resume a consult
  resumeConsult(consult: Consult) {
    if (consult.form_submission && consult.patient) {
      this.$router.push(
        `/patients/${consult.patient.id}/forms/${consult.form_submission.id}`
      );
    }
  }

  // Start review
  async startReview(consult: Consult) {
    if (consult.patient) {
      await this.$router.push(
        `/patients/${consult.patient.id}/consults/${consult.id}/image-review`
      );
    }
  }

  async continueConsult(
    patientId: string,
    consultId: string,
    reviewId: string
  ) {
    const patientReviewService = new PatientReviewService(patientId);
    await patientReviewService.lock(reviewId, this.sessionStore.currentUser.id);
    return this.$router.push(
      `/patients/${patientId}/consults/${consultId}/image-review`
    );
  }

  // Reject consult
  openRejectReasonModal(consult: Consult) {
    this.selectedConsultId = consult.id;
    this.currentConsult = consult;
    this.selectedPatient = consult.patient ? consult.patient : null;
    this.enableRejectReasonModal = true;
  }

  closeRejectReasonModal() {
    this.selectedConsultId = '';
    this.currentConsult = null;
    this.selectedPatient = null;
    this.enableRejectReasonModal = false;
    this.fetchConsults();
  }

  closeResolveReasonModal() {
    this.selectedConsultId = '';
    this.currentConsult = null;
    this.selectedPatient = null;
    this.enableResolveReasonModal = false;
    this.fetchConsults();
  }

  async markAsRejected(rejectedReason: RejectReasonValue) {
    try {
      const data = {
        rejected_at: dayjs().utc().toISOString(),
        rejected_reason_type: rejectedReason.selectedReason,
        rejected_reason: rejectedReason.additionalReason
      };
      await this.consultService.update(this.selectedConsultId, data);
      await this.fetchConsultCount(this.getStatusIndexByName('Rejected'));
      await this.fetchConsultCount(this.getStatusIndexByName('Unscheduled'));
      await this.fetchConsultCount(this.getStatusIndexByName('Scheduled'));
      await this.fetchConsultCount(this.getStatusIndexByName('Reviewing'));
      await this.fetchConsultCount(this.getStatusIndexByName('Consulting'));
      this.closeRejectReasonModal();
    } catch (e: any) {
      if (e.response.status === 422) {
        this.rejectReasonError = this.extractRejectedErrorMessageFromResponseErrors(e.response.data.errors);
      }
      await this.notificationStore.addErrorNotification({
        title: this.$t('custom.uhb.consult.update-error')
      });
    }
  }

  updateRejectErrorMessage(value: string) {
    this.rejectReasonError = value;
  }

  updateResolveErrorMessage(value: string) {
    this.resolveReasonError = value;
  }

  extractRejectedErrorMessageFromResponseErrors(errors: IErrors): string|null {
    let errorMessages: Array<string> = [];
    if (errors.rejected_reason) {
      errorMessages = errorMessages.concat(errors.rejected_reason);
    }
    if (errors.rejected_reason_type) {
      errorMessages = errorMessages.concat(errors.rejected_reason_type);
    }
    if (errorMessages.length === 0) {
      return null;
    }
    return errorMessages.join(' ');
  }

  extractResolvedErrorMessageFromResponseErrors(errors: IErrors): string|null {
    let errorMessages: Array<string> = [];
    if (errors.resolved_reason) {
      errorMessages = errorMessages.concat(errors.resolved_reason);
    }
    if (errors.resolved_reason_type) {
      errorMessages = errorMessages.concat(errors.resolved_reason_type);
    }
    if (errorMessages.length === 0) {
      return null;
    }
    return errorMessages.join(' ');
  }

  // Resolve consult
  showResolveModal(consult: Consult) {
    this.selectedConsultId = consult.id;
    this.currentConsult = consult;
    this.selectedPatient = consult.patient ? consult.patient : null;
    this.enableResolveReasonModal = true;
  }

  closeResolveModal() {
    this.selectedConsultId = '';
    this.enableResolveReasonModal = false;
    this.fetchConsults();
  }

  async markAsResolved(resolveReason: ResolveReasonValue) {
    try {
      const data = {
        resolved_rejected_at: dayjs().utc().toISOString(),
        resolved_reason_type: resolveReason.selectedReason,
        resolved_reason: resolveReason.additionalReason
      };
      await this.consultService.update(this.selectedConsultId, data);
      await this.fetchConsultCount(this.getStatusIndexByName('Rejected'));
      this.closeResolveReasonModal();
    } catch (e) {
      if (e.response.status === 422) {
        this.resolveReasonError = this.extractResolvedErrorMessageFromResponseErrors(e.response.data.errors);
      }
      await this.notificationStore.addErrorNotification({
        title: this.$t('custom.uhb.consult.update-error')
      });
    }
  }

  // Lock/unlock review
  clickUnlockConsult(
    patient: Patient,
    reviewId: string,
    lockedByUserFullName: string
  ) {
    this.selectedPatient = patient;
    this.modalTargetReviewId = reviewId;
    this.modalTargetLockedByUserFullName = lockedByUserFullName;
    this.enableLockConsultModal = true;
  }

  closeLockConsultModal() {
    this.modalTargetReviewId = '';
    this.modalTargetLockedByUserFullName = '';
    this.enableLockConsultModal = false;
  }

  async unlockConsult(patientId: string, reviewId: string) {
    const patientReviewService = new PatientReviewService(patientId);
    this.enableLockConsultModal = false;
    await patientReviewService.unlock(reviewId);
    return this.fetchConsults();
  }

  // View Patient Letter of completed consult
  viewPatientLetter(consult: Consult) {
    this.enablePatientLetterModal = true;
    this.selectedPatient = consult.patient || null;
    this.modalTargetReviewId = (consult.review && consult.review.id) || '';
  }

  closePatientLetter() {
    this.enablePatientLetterModal = false;
    this.modalTargetReviewId = '';
    this.selectedPatient = null;
  }

  // Resend Patient Letter
  async resendPatientLetter(patientId: string, reviewId: string) {
    try {
      const patientReviewService = new PatientReviewService(patientId);
      await patientReviewService.resend(reviewId, this.organisationId);

      await this.notificationStore.addSuccessNotification({
        title: this.$t('custom.uhb.correspondence.resend-successful')
      });
    } catch (exception) {
      if (exception.response.status === 400) {
        await this.notificationStore.addErrorNotification({
          title: this.$t('custom.uhb.correspondence.resend-failed.title'),
          label: this.$t('custom.uhb.correspondence.resend-failed.label')
        });
      } else {
        await this.notificationStore.addErrorNotification({
          title: this.$t('custom.uhb.correspondence.resend-failed.title')
        });
      }
    }
  }

  // View Patient Review Summary
  openViewAndCopyReviewSummaryModal(consult: Consult) {
    this.viewAndCopyReviewSummary = true;
    this.selectedPatient = consult.patient || null;
    this.modalTargetReviewId = (consult.review && consult.review.id) || '';
    this.modalTargetClinic = consult.clinic;
    if (consult.completed_at) {
      this.modalTargetConsultCompletedAt = consult.completed_at;
    }
    if (consult.form_submission && consult.clinician) {
      this.modalTargetFormSubmissionId = consult.form_submission.id;
      this.modalTargetFormSubmissionClinician = consult.clinician;
    }
  }

  closeViewAndCopyReviewSummaryModal() {
    this.viewAndCopyReviewSummary = false;
    this.selectedPatient = null;
    this.modalTargetReviewId = '';
    this.modalTargetClinic = null;
    this.modalTargetConsultCompletedAt = '';
    this.modalTargetFormSubmissionId = '';
    this.modalTargetFormSubmissionClinician = '';
  }
}
